.shopping-cart th{
    color: #ededed;
}
.shopping-cart td{
    border: 1px solid #d7d7d7;
}
.shopping-cart .table thead th {
   vertical-align: middle;
}

.shopping-cart .img-name{
    display: flex;
    align-items: center;
    /* justify-content: space-around; */

}

/* .shopping-cart .img-name div{
    width: 100%;
    text-align: center;
} */

.shopping-cart .img-name img{
    width: 80px;
    height: 80px;
}