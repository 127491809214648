.catd-item a {
    text-decoration: none;
}

.product-item {
    cursor: pointer;
    -webkit-box-shadow: 0px 21px 32px -13px rgba(0, 0, 0, 0.59);
    -moz-box-shadow: 0px 21px 32px -13px rgba(0, 0, 0, 0.59);
    box-shadow: 0px 21px 32px -13px rgba(0, 0, 0, 0.59);
}

.custom-control>input,
.custom-control>label {
    cursor: pointer;
}

.it {
    display: flex;
}

.title-product {
    height: 40px;
    line-height: 1.2;
    vertical-align: middle;
    padding: 0 10px;
    font-weight: bold;
    color: #cecece;
    border-bottom: 1px solid #5b5b5b;
    display: flex;
    align-items: center;
    justify-content: center;
}

.card-header {
    /* border-color: #afafaf !important; */
    height: 320px;
    display: flex;
    /* align-items: center; */
}

.dEViGe {
    align-items: center;
}

.card-header img {
    /* height: 305px; */
    object-fit: cover;
    width: 100%;
    max-height: 100%;
}

.card-body {
    background-color: #000000ad;
    color: #bbbbbb;
    border-color: #afafaf !important;
}

.description {
    line-height: 1.2;
    padding: 0 4px;
    padding-top: 8px;
    margin-bottom: 6px;
    font-size: 14px;
    height: 95px;
    align-items: center;
    display: -webkit-box !important;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    -webkit-box-align: center;
    -webkit-box-pack: center;
    overflow: hidden;
    text-overflow: ellipsis;
}

.infinite-scroll-component {
    overflow: hidden !important;
}

.product-price {
    border-top: 1px solid #5b5b5b;
    color: #D19C97;
    padding: 5px 0;
}

.card-footer {
    padding: 0;
    justify-content: space-evenly;
    border-color: #afafaf !important;
    background-color: #000000ad !important;
}

.card-footer>i {
    display: flex;
    align-items: center;
}

.card-footer>i:hover {
    color: #afafaf !important;
    cursor: pointer;
}

.main-video {
    background-size: cover;
    mask: linear-gradient(180deg, rgba(255, 255, 255, 0.014)0%, rgba(0, 0, 0, 0.486) 5%, rgb(0, 0, 0) 50%);
    position: relative;
    width: 140%;
    left: -20%;
    z-index: 8;
}

.aaa {
    min-height: 700px;
    position: static;
    z-index: 90;
}

.color-w {
    color: white;
}

.filer_name{
    color: #d1d1d1 !important;
    padding: 2px 5px;
    border: 1px solid #dbdbdb;
    margin: 0 10px 0 -20px;
}

.filer_crumb {
    padding: 20px 0;
    margin-top: -50px;
}

.filer_crumb i {
    padding: 5px;
}

.filer_crumb i:hover {
    cursor: pointer;
    color: #D19C97;
}

.filer_crumb span {
    color: #D19C97;
}

@media (max-width: 525px) {
    .aaa {
        display: none;
    }
}

/* .background-video video {
    /* position: fixed; */
/* right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%; */