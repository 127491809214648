.fix-bg {
    position: fixed;
    width: 100%;
    top: 300px;
    left: 0;
    z-index: 1;
    height: 300px;
}

.fix-bg>img {
    width: 100%;
}

.bg-white {
    background-color: #ffffffad !important;
    /* z-index: 1; */
}

.modal-edit {
    position: absolute;
    z-index: 999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.658);

}

.modal-edit-item {
    margin: 50px auto;
    width: 90%;
    min-height: 50%;
    background-color: white;
    padding: 50px;
    -webkit-box-shadow: 1px 12px 22px -6px rgb(165, 165, 165);
    -moz-box-shadow: 1px 12px 22px -6px rgb(165, 165, 165);
    box-shadow: 1px 12px 22px -6px rgb(165, 165, 165);

}

.slick-dots {
    bottom: -30px;
    display: flex !important;
    justify-content: center;
    /* min-height: 50px; */
}

.slider-item video {
    /* padding-bottom: 100px; */
    /* margin-bottom: 40px; */
}

.type-group {
    display: flex;
    color: #a39999;
    flex-wrap: wrap;
}

.type-group>div {
    padding-left: 10px;
    color: #6F6F6F;
}

.slick-prev,
.slick-next {
    z-index: 99 !important;
}

.slick-prev:before,
.slick-next:before {
    color: #d19c97 !important;
    font-size: 40px;
    margin-left: -11px;
}

.slider-group .slider-item img {
    transition: transform .4s;
}

.slider-group .slider-item:hover img {
    transform: scale(1.5);
}
.color-item-group{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

.color-item {
    display: grid;
    justify-items: center;
    margin: 5px;
}

.color-item div {
    width: 25px;
    height: 25px;
    border: 1px solid #cccccc;
    text-align: center;
    font-size: 15px;
    cursor: pointer;
}

.color-item div.active {
    border: 3px solid #2b2b2b;
}

.color-item div.active i{
    color: white;
}

.color-item .red {
    background-color: red;
}

.color-item .gold {
    background-color: gold;
}

.color-item .silver {
    background-color: silver;
}

.color-item .white {
    background-color: white;
}

.color-item .black {
    background-color: black;
}
.color-item .bronze {
    background-color: rgb(164, 96, 7);
}



/* .color-item .yellow-blue {
    background: linear-gradient(to right,  yellow 50%, blue 50%);
}
.color-item .red-green {
    background: linear-gradient(to right,  red 50%, green 50%);
}
.color-item .white-gold {
    background: linear-gradient(to right,  white 50%, gold 50%);
} */

.color-item .black-purple {
    background: linear-gradient(180deg,  black 50%, purple 50%);
}
.color-item .blue-gold {
    background: linear-gradient(180deg,  blue 50%, gold 50%);
}
.color-item .burgundy-green {
    background: linear-gradient(180deg,  #800020 50%, green 50%);
}


.slider-more .slick-slide{
    /* max-height: 200px; */
    overflow: hidden;
    padding: 0 10px;
    padding-bottom: 15px;
}

.btn-details{
    margin: 4px;
   /* background-color: #d19c9782; */

}

.btn-details:hover{
    color: white !important;
}