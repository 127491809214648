.w-130 {
    width: 130px !important;
}

.orders .img-name div {
    line-height: 1;
}

.edit-icon {
    cursor: pointer;
    position: relative;
}

.edit-icon:hover .edit-body {
    display: block;
}

.edit-body {
    display: none;
    border: 1px solid rgb(133, 133, 133);
    width: 160px;
    position: absolute;
    text-align: left;
    top: 0;
    right: 5px;
    z-index: 9;
    background-color: rgb(89 89 89);
    -webkit-box-shadow: -7px 6px 28px -8px rgba(255, 255, 255, 1);
    -moz-box-shadow: -7px 6px 28px -8px rgba(255, 255, 255, 1);
    box-shadow: -7px 6px 28px -8px rgba(255, 255, 255, 1);
}

.edit-body>div {
    padding: 5px 10px;
    border-bottom: 1px solid rgb(184, 184, 184);
    color: #a9a9a9;
}

.edit-body>div:hover {
    color: white;
}

.edit-body .yellow,
.status-td .yellow {
    color: #e1e19b;
}

.edit-body>div i,
.status-td i {
    color: #9393ff;
    padding-right: 10px;
}

.edit-body>div .red,
.status-td .red {
    color: red;
}

.filter-btns {
    align-items: center;
    margin-left: 50px;
}

.filter-btns label {
    margin: 10px 3px;
}

.filter-btns label.active {
    background-color: #cf766e !important;
}

.filter-btns i {
    font-size: 20px;
    padding: 0 10px;
}