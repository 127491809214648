.video-desc {
    font-size: 1.4vw;
}

.g-align {
    display: flex;
    align-items: center;
    line-height: 1.6;
    font-size: 105%;
}

.v-item {
    display: flex;
    align-items: center;
    padding: 20px 10px;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
}

.v-item video {
    border: 4px solid #ffffff;
}

.v-item video,
.v-item img {
    -webkit-box-shadow: 0px 0px 52px -20px rgba(0, 0, 0, 0.68);
    -moz-box-shadow: 0px 0px 52px -20px rgba(0, 0, 0, 0.68);
    box-shadow: 0px 0px 52px -20px rgba(0, 0, 0, 0.68);
    margin: 5px;
}

.sm-desc div {
    margin: 15px 0;
    line-height: 1.2;
}

@media (max-width: 768px) {

    .v-item {
        justify-content: center;
    }

    .video-desc {
        font-size: 2.7vw;
    }

    .v-item video {
        width: 100%;
    }
}