.navbar {
    z-index: 9 !important;
}

.large-icon i {
    font-size: 23px;
}

.navbar-nav {
    align-items: center;
}

.logo-main {
    width: 257px;
    /* height: 87px; */
    margin-left: -15px;
}

.logo-main>img {
    width: 100%;
    height: 100%;
}


.logo-sm-main {
    width: 135px;
}

.logo-sm-main>img {
    width: 100%;
    height: 100%;
}


.logo-footer {
    width: 220px;
    margin: 0 auto;
}

.logo-footer>img {
    width: 100%;
    height: 100%;
}

.language {
    display: flex;
    align-items: center;
    justify-content: center;
}

.language div {
    padding: 0 3px;
    cursor: pointer;
}

.login-btn {
    cursor: pointer;
}

.language div.active {
    border: 1px solid #b0b0d5;
}

.user-name:hover .m-items {
    display: block;
}

.m-items {
    border: 1px solid #e9e9e9;
    background-color: #ffffff;
    cursor: pointer;
    position: absolute;
    display: none;
}

.m-item {
    border-bottom: 1px solid #e9e9e9;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: gray !important;
    text-decoration: none !important;
}

.m-item>i {
    padding-left: 10px;
    color: #afafaf;
}

.m-item:hover {
    background-color: #f3f3f3;
}

.social-i {
    text-align: right;
    display: flex;
    justify-content: center;
    width: 100%;
}

.social-i i {
    font-size: 28px;
    /* padding-left: 54px; */
    padding-top: 2px
}