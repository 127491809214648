#preview {
    display: flex;
}

.dashbord-page {
    position: relative;
}

.dashbord-page h5 {
    text-align: center;
}

#preview img,
#preview video {
    width: 200px;
    margin: 10px;
}

.imgs {
    display: flex;
}

.imgs .imgs-item {
    width: 500px;
    margin: 10px;
    position: relative;
    border: 1px solid #efefef;
}

.imgs .imgs-item .imgs-item-btn {
    padding: 10px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #efefef;

}

.imgs .imgs-item .imgs-item-btn i {
    /* position: absolute; */
    /* top: -20px; */
    /* right: -15px; */
}

.imgs .imgs-item i:hover {
    color: blue;
    cursor: pointer;
}

.imgs .imgs-item img {
    width: 100%;
}

.details-btn {
    text-align: right;
}

.details-btn button {
    margin: 10px;
    width: 100px;
}

.form-check div:hover input,
.form-check div:hover label {
    cursor: pointer !important;
}

.close-btn {
    position: absolute;
    right: 10px;
    top: -40px;
    cursor: pointer;
}

.close-btn:hover i{
    color: blue;

}